/**
 *
 * @param {desktop, tablet, mobile} sizes in vw
 * @param inPreview to determine whether to convert vw to pixels for preview
 * @returns string font sizes values for desktop, tablet and mobile
 */
export const getFontSizeOverride = (
  {
    desktop,
    tablet,
    mobile,
    mobileXs,
  }: { desktop: number; tablet: number; mobile: number; mobileXs?: number },
  inPreview?: 'DESKTOP' | 'MOBILE'
) => {
  if (inPreview) {
    const convertVwToPx = (v: number) => {
      const screenSize = inPreview === 'DESKTOP' ? 1280 : 580;
      return (v / 100) * screenSize;
    };

    return {
      desktop: `${convertVwToPx(desktop)}px`,
      tablet: `${convertVwToPx(tablet)}px`,
      mobile: `${convertVwToPx(mobile)}px`,
      mobileXs: mobileXs ? `${convertVwToPx(mobileXs)}px` : '',
    };
  }
  return {
    desktop: `${desktop}vw`,
    tablet: `${tablet}vw`,
    mobile: `${mobile}vw`,
    mobileXs: mobileXs ? `${mobileXs}vw` : '',
  };
};

// pre:
// post:
export const getResponsiveNamesFontSizeInVW = ({
  ownerFirstName,
  ownerLastName,
  partnerFirstName,
  partnerLastName,
  remainingSpace,
}: {
  ownerFirstName: string | undefined;
  ownerLastName: string | undefined;
  partnerFirstName: string | undefined;
  partnerLastName: string | undefined;
  remainingSpace: number;
}): number => {
  const getMaxWordLength = (s: string) => {
    const hyphenSplitArr = s.split('-');
    const splitArr = hyphenSplitArr.map((st) => st.split(' ')).flat();
    const lengthArr = splitArr.map((w) => w.length);
    return Math.max(...lengthArr);
  };
  const maxNameLength = Math.max(
    getMaxWordLength(ownerFirstName || ''),
    getMaxWordLength(ownerLastName || ''),
    getMaxWordLength(partnerFirstName || ''),
    getMaxWordLength(partnerLastName || '')
  );
  return remainingSpace / maxNameLength;
};
