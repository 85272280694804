import React, { useRef } from 'react';
import ImageBaseComponent from '~/components/publicWebsiteV2/common/Image';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';

type FooterImageProps = {
  imageUrl?: string;
  className?: string;
};

const FooterImage = ({ imageUrl, className }: FooterImageProps): JSX.Element | null => {
  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  if (!imageUrl) return null;

  return (
    <div className={className} ref={containerRef}>
      <ImageBaseComponent
        src={imageUrl}
        alt="Footer image"
        containerWidth={containerWidth}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default FooterImage;
