import React, { useContext } from 'react';
// import NextImage from 'next/image';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { DeviceContext } from '~/contexts/DeviceContext';
import type {
  WAbsoluteThemeComponentAssetStyleView,
  WLengthView,
} from '@zola/svc-web-api-ts-client';
import { appendWidthParam } from '../../util/getRelativeImgSize';

export interface ImageProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    React.AriaAttributes {
  src?: string;
  alt?: string;
  className?: string;
  containerWidth?: number;
  assetStyles?: WAbsoluteThemeComponentAssetStyleView;
  hasAnimation?: boolean;
}

const defaultAssetStyle: WAbsoluteThemeComponentAssetStyleView = {
  responsive_size: {
    desktop: {
      width: { unit: ('PERCENTAGE' as unknown) as WLengthView.UnitEnum, value: 100 },
    },
  },
};

const Image = ({
  src,
  alt,
  className,
  containerWidth,
  assetStyles = defaultAssetStyle,
  hasAnimation,
  ...rest
}: ImageProps): JSX.Element | null => {
  const {
    state: { inPreview },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);

  if (!src) return null;
  const imageUrl = appendWidthParam({ src, style: assetStyles, width: containerWidth, device });
  return (
    <img
      className={className}
      src={imageUrl}
      alt={alt}
      loading={!inPreview ? 'lazy' : undefined}
      {...rest}
    />
  );
};

export default Image;
