import React from 'react';
import type { Dayjs } from 'dayjs';
import styled from '@emotion/styled';

import mapFontValuesToComponent from '~/components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';
import formatLocation from '~/components/publicWebsiteV2/util/formatLocation';
import { FoilAnimationWrapper } from '~/components/publicWebsiteV2/common/FoilAnimation/FoilAnimationWrapper/FoilAnimationWrapper';
import { WeddingDateDisplayFormatEnum } from '../../../../../../util/websiteDateUtil';
import { DisplayTypeProps } from './props';
import { VerticalContainer, VerticalLocationContainer, To } from '../LocationDateModule.styles';

const LocationDateModuleVertical = ({
  className,
  city,
  state,
  websiteDate,
  headerFontProps,
  bodyFontProps,
  textAlignment,
}: DisplayTypeProps): JSX.Element | null => {
  const H3NoMargin = styled.h3`
    margin: 0; // Defensive styling for bootstrap styles applied in preview
  `;
  const H3 = mapFontValuesToComponent({
    ...headerFontProps,
    ComponentType: H3NoMargin,
  });

  const renderDateFormat = (): React.ReactNode | null => {
    const renderDate = (d?: Dayjs) => (
      <H3>
        <FoilAnimationWrapper
          text={`${d?.format('MMMM')} ${d?.format('D')}, ${d?.format('YYYY')}`}
        />
      </H3>
    );

    if (websiteDate?.displayFormat === WeddingDateDisplayFormatEnum.CUSTOM) {
      const BodyFontDivComponent = mapFontValuesToComponent({
        ...bodyFontProps,
        ComponentType: 'div',
      });

      return <BodyFontDivComponent>{websiteDate.customDateMessage}</BodyFontDivComponent>;
    }
    if (websiteDate?.displayFormat === WeddingDateDisplayFormatEnum.RANGE) {
      const { weddingDateRangeStart, weddingDateRangeEnd } = websiteDate || {};
      const sameYear = weddingDateRangeStart?.get('year') === weddingDateRangeEnd?.get('year');
      const sameMonth =
        sameYear && weddingDateRangeStart?.get('month') === weddingDateRangeEnd?.get('month');

      if (sameMonth) {
        return (
          <H3>
            <FoilAnimationWrapper
              text={`${weddingDateRangeStart?.format('MMMM')} ${weddingDateRangeStart?.format(
                'D'
              )} -
            ${weddingDateRangeEnd?.format('D')}, ${weddingDateRangeEnd?.format('YYYY')}`}
            />
          </H3>
        );
      }

      if (sameYear) {
        return (
          <H3>
            <FoilAnimationWrapper
              text={`${weddingDateRangeStart?.format('MMMM')} ${weddingDateRangeStart?.format(
                'D'
              )} -
            ${weddingDateRangeEnd?.format('MMMM')} ${weddingDateRangeEnd?.format('D')},
            ${weddingDateRangeEnd?.format('YYYY')}`}
            />
          </H3>
        );
      }

      return (
        <>
          {renderDate(weddingDateRangeStart)}
          <To>-</To>
          {renderDate(weddingDateRangeEnd)}
        </>
      );
    }
    if (websiteDate?.weddingDate) {
      return renderDate(websiteDate?.weddingDate);
    }
    return null;
  };

  const StyledVerticalLocationContainer = mapFontValuesToComponent({
    ...bodyFontProps,
    ComponentType: VerticalLocationContainer,
  });

  return (
    <VerticalContainer className={className} textAlignment={textAlignment}>
      {renderDateFormat()}
      <StyledVerticalLocationContainer>
        {formatLocation(city, state)}
      </StyledVerticalLocationContainer>
    </VerticalContainer>
  );
};

export default LocationDateModuleVertical;
