import React, { useEffect, useState } from 'react';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { calculateTimeLeft, getCountdownStrings, TimeUnitStringMapType } from './helpers';
import { TimeInterval, DesktopLabel, MobileLabel } from './DateCountdown.styles';

const DateCountdown = ({ startDate }: { startDate: string }): JSX.Element | null => {
  const {
    state: {
      components: { styleGlobalBodyFont },
    },
  } = useWebsiteThemeContext();

  const [timeLeft, setTimeLeft] = useState<Partial<Record<keyof TimeUnitStringMapType, number>>>(
    calculateTimeLeft(startDate)
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(startDate));
    }, 1000);
    return () => clearTimeout(timer);
  });

  if (!startDate) return null;

  const countdownString = getCountdownStrings(timeLeft);

  if (!countdownString) return null;

  const Countdown = styleGlobalBodyFont('div');

  return (
    <Countdown>
      {countdownString.map((s, i) => (
        <TimeInterval addSpacing={i !== 0} key={i}>
          <DesktopLabel>{s.DESKTOP}</DesktopLabel>
          <MobileLabel>{s.MOBILE}</MobileLabel>
        </TimeInterval>
      ))}
    </Countdown>
  );
};

export default DateCountdown;
