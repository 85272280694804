import React from 'react';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { useTheme } from '@emotion/react';
import { mapWeddingAccountToWebsiteDateProp } from '../../../../../util/websiteDateUtil';
import LocationDateModuleHorizontal from './displayTypes/LocationDateModuleHorizontal';
import LocationDateModuleVertical from './displayTypes/LocationDateModuleVertical';
import { Container } from './LocationDateModule.styles';

type LocationDateModuleProps = {
  className?: string;
  textAlignment: string;
};

const LocationDateModule = ({
  className,
  textAlignment,
}: LocationDateModuleProps): JSX.Element | null => {
  const {
    state: {
      wedding,
      components: { globalHeaderFontValues, globalBodyFontValues },
    },
  } = useWebsiteThemeContext();
  const theme = useTheme();

  if (!wedding) return null;

  const { city, state_province } = wedding;
  const websiteDate = mapWeddingAccountToWebsiteDateProp(wedding, undefined);
  const hasWeddingLocation = Boolean(city || state_province);

  if (!websiteDate && !hasWeddingLocation) return null;

  const dateLocationComponent = wedding?.public_theme_v2?.components?.DATE_AND_LOCATION;
  const orientation = dateLocationComponent?.option_type_values?.ORIENTATION?.value;

  const headerFontProps = {
    fallback: globalHeaderFontValues,
    partial: dateLocationComponent?.fonts?.HEADER?.[1],
    responsiveFontSize: true,
    mediaQuery: theme.MEDIA_QUERY,
  };
  const bodyFontProps = {
    fallback: globalBodyFontValues,
    partial: dateLocationComponent?.fonts?.BODY?.[1],
    mediaQuery: theme.MEDIA_QUERY,
  };

  const props = {
    city,
    state: state_province,
    websiteDate,
    headerFontProps,
    bodyFontProps,
    textAlignment,
  };

  return (
    <Container className={className}>
      {orientation === 'HORIZONTAL' ? (
        <LocationDateModuleHorizontal {...props} />
      ) : (
        <LocationDateModuleVertical {...props} />
      )}
    </Container>
  );
};

export default LocationDateModule;
