import React from 'react';

// utils
import { getCountryFromCountryCode } from '~/components/common/form/CountryCodes';

export type AddressProps = {
  addressData: {
    name?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    countryCode?: string;
  };
  singleLine?: boolean;
  className?: string;
};

/**
 * Returns true if the Address component will render.
 */
export const hasRenderableAddress = (addressData: AddressProps['addressData']): boolean => {
  const { name, address1, address2, city, state, zip, countryCode } = addressData;

  if (
    !name &&
    !address1 &&
    !address2 &&
    !city &&
    !state &&
    !zip &&
    (!countryCode || countryCode === 'US')
  ) {
    return false;
  }
  return true;
};

const Address: React.FC<AddressProps> = ({ addressData, className, singleLine }) => {
  const { name, address1, address2, city, state, zip, countryCode } = addressData;

  const renderDomesticCityState = () => (city ? `${city}${state || zip ? `, ` : ''}` : '');

  const renderInternationalCityState = () => (city ? `${city}, ` : '');

  const renderCountryStateAndZip = () =>
    `${
      countryCode && countryCode === 'US'
        ? renderDomesticCityState()
        : renderInternationalCityState()
    }${state ? `${state}${zip ? ' ' : ''}` : ''}${zip ? `${zip}` : ''}${
      countryCode && countryCode !== 'US'
        ? `${state || zip ? ', ' : ''}${getCountryFromCountryCode(countryCode)}`
        : ''
    }`;

  if (!hasRenderableAddress(addressData)) {
    return null;
  }

  if (singleLine) {
    return (
      <address className={className} data-testid="WeddingWebsiteAddress">
        {`${name ? `${name}, ` : ''}${address1 ? `${address1}, ` : ''}${
          address2 ? `${address2}, ` : ''
        }${renderCountryStateAndZip()}`}
      </address>
    );
  }

  return (
    <address className={className} data-testid="WeddingWebsiteAddress">
      {name && (
        <>
          {name}
          <br />
        </>
      )}
      {address1 && (
        <>
          {address1}
          <br />
        </>
      )}
      {address2 && (
        <>
          {address2}
          <br />
        </>
      )}
      {renderCountryStateAndZip()}
    </address>
  );
};

export default Address;
