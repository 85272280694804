import styled from '@emotion/styled';
import type { WInFlowThemeComponentAssetSummaryView } from '@zola/svc-web-api-ts-client';
import { mapInFlowAssetToCSS } from '~/components/publicWebsiteV2/util/mappers';
import type { DevicePartial } from '~/components/publicWebsiteV2/util/getRelativeImgSize';

export const HomeContentContainer = styled.div<{
  containerWidth?: number;
  device?: DevicePartial;
  inFlowAssets?: WInFlowThemeComponentAssetSummaryView;
}>`
  position: relative;
  overflow: visible;
  padding-top: 1px; // prevents margin from collapsing: https://stackoverflow.com/questions/1762539/margin-on-child-element-moves-parent-element
  margin-top: -1px;
  ${({ inFlowAssets, device, containerWidth, theme }) =>
    mapInFlowAssetToCSS(inFlowAssets, device, containerWidth, theme.MEDIA_QUERY)}
`;
