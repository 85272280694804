import React, { useContext, useRef } from 'react';
import type { WCmsHomepageSectionView, WCmsVideoView } from '@zola/svc-web-api-ts-client';
import { DeviceContext } from '~/contexts/DeviceContext';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { pickDeviceProperties } from '~/components/publicWebsiteV2/util/getRelativeImgSize';
import { PageAnimationWrapper } from '~/components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import { FoilAnimationProvider } from '~/components/publicWebsiteV2/common/FoilAnimation/FoilAnimationContext/FoilAnimationProvider';
import Story from './Story';
import { Container } from './StorySection.styles';
import { renderAbsoluteAssets } from '../../../util/renderHelpers';

const StorySection = ({
  homepageSections,
  video,
  className,
  textAlignment,
  disableAssets,
}: {
  homepageSections?: WCmsHomepageSectionView[];
  video?: WCmsVideoView;
  className?: string;
  textAlignment: string;
  disableAssets?: boolean;
}): JSX.Element | null => {
  const {
    state: { wedding },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);

  const { CMS_STORIES: homepageStoryComponent } = wedding?.public_theme_v2?.components || {};
  const { option_type_values, absolute_assets, in_flow_assets } = homepageStoryComponent || {};

  const orientation = option_type_values?.ORIENTATION?.value;

  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  if (video || (homepageSections && homepageSections.length > 0)) {
    return (
      <Container
        className={className}
        containerWidth={containerWidth}
        device={pickDeviceProperties(device)}
        inFlowAsset={disableAssets ? undefined : in_flow_assets}
        ref={containerRef}
      >
        {disableAssets
          ? null
          : renderAbsoluteAssets({ a: absolute_assets, containerWidth, device })}
        {homepageSections?.map((s, i) => {
          const direction = i % 2 ? 'right' : 'left';
          return (
            <PageAnimationWrapper
              index={i}
              key={i}
              animationProps={{ direction: { wipeDirection: direction, panDirection: direction } }}
              intersectionObserverThreshold={0}
            >
              <FoilAnimationProvider>
                <Story
                  {...s}
                  key={i}
                  textAlignment={textAlignment}
                  orientation={orientation || 'VERTICAL'}
                  disableAssets={disableAssets}
                />
              </FoilAnimationProvider>
            </PageAnimationWrapper>
          );
        })}
      </Container>
    );
  }

  return null;
};

export default StorySection;
