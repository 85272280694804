import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Address from '~/components/publicWebsiteV2/common/Address';

import { SPACING } from '@zola/zola-ui/src/styles/emotion';

type ContainerProps = { orientation?: string };

export const Container = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme?.SPACING?.XL};
  text-align: center;
  ${(props) => props.theme?.MEDIA_QUERY?.EXCLUDE_DESKTOP} {
    margin-top: 60px;
  }
`;

export const Event = styled.div<{ shouldRenderDate?: boolean; forceTopMargin?: boolean }>`
  overflow-wrap: anywhere;

  margin-top: ${(props) => (props.forceTopMargin ? '60px' : 'auto')};

  & + & {
    margin-top: 60px;
  }

  ${(props) => props.theme?.MEDIA_QUERY?.DESKTOP} {
    margin-top: ${({ shouldRenderDate, theme }) => (shouldRenderDate ? theme.SPACING.XL : '60px')};
  }
`;

export const DesktopDate = styled.h2`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${(props) => props.theme?.SPACING?.S32};
`;

export const MobileName = styled.h2`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${(props) => props.theme?.SPACING?.XS};
`;

export const DesktopName = styled.h3`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${(props) => props.theme?.SPACING?.XXS};
  ${(props) => props.theme?.FONTS?.textSizeCompact(props.theme?.FONTS?.FONT_SIZE_LARGE)};
  font-weight: ${(props) => props.theme?.FONTS?.FONT_WEIGHT_BOLD};
`;

export const StyledAddress = styled(Address)`
  margin: 0; // Defensive styling for bootstrap styles applied in preview
  &:empty {
    display: none;
  }
`;

export const Venue = styled.div<ContainerProps>`
  margin-bottom: ${(props) => props.theme?.SPACING?.S24};
  ${({ orientation }) =>
    orientation === 'HORIZONTAL' &&
    `
      margin-bottom: 0;
  `}
  &:empty {
    display: none;
  }

  ${(props) => props.theme?.MEDIA_QUERY?.MOBILE} {
    margin-bottom: ${(props) => props.theme?.SPACING?.S32};
  }
`;

export const ButtonContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > *,
  & > a {
    margin: ${(props) => props.theme?.SPACING?.S10} 0;

    ${(props) => props.theme?.MEDIA_QUERY?.MOBILE} {
      margin: ${(props) => props.theme?.SPACING?.S16} 0;
    }
  }

  ${(props) => props.theme?.MEDIA_QUERY?.DESKTOP} {
    flex-direction: row;
    ${({ orientation }) =>
      orientation === 'HORIZONTAL' &&
      `
    flex-direction: column;
  `}
    & > * {
      margin: ${({ theme, orientation }) =>
        orientation === 'HORIZONTAL' ? `${theme?.SPACING?.S10} 0` : `0 ${theme?.SPACING?.S10}`};
    }
  }
`;

export const sharedButtonStyles = css`
  width: 217px;
`;

export const EmptyStateMessage = styled.p`
  text-align: center;
`;

export const AfterDate = styled.div<ContainerProps>`
  display: flex;
  position: relative;

  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    flex-direction: column;
  }
  ${({ orientation }) =>
    orientation === 'VERTICAL' &&
    `
    flex-direction: column;
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin: 0 auto ${SPACING.S24} auto;

  svg {
    transform: scale(1.4);
    height: auto;
    width: 100%;
  }
`;
