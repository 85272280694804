import React, { useContext, useRef } from 'react';
import Footer from '~/components/publicWebsiteV2/common/Footer';
import InnerContainer from '~/components/publicWebsiteV2/common/InnerContainer';
import type { WCmsHomepageView } from '@zola/svc-web-api-ts-client';
import { renderAbsoluteAssets } from '~/components/publicWebsiteV2/util/renderHelpers';
import { isDarkColor } from '~/pages/publicStyleUtils/utils.styles';

import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { DeviceContext } from '~/contexts/DeviceContext';
import { mapBackgroundToInlineStyle } from '~/components/publicWebsiteV2/util/mappers';
import useCroppedBottomAbsoluteAssets from '~/components/publicWebsiteV2/util/useCroppedBottomAbsoluteAssets';
import { pickDeviceProperties } from '~/components/publicWebsiteV2/util/getRelativeImgSize';

import MultiPageHeroContainer from '~/components/publicWebsiteV2/common/MultiPageHeroContainer';

import Home from '../Home';
import { HomeContentContainer } from './HomeContainer.styles';

const HomeContainer = ({
  pageData,
  isSamplePage,
}: {
  pageData?: WCmsHomepageView;
  isSamplePage?: boolean;
}): JSX.Element => {
  const {
    state: {
      wedding,
      components: { backgroundColors },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const { device } = useContext(DeviceContext);
  const bodyContentRef = useRef<HTMLDivElement>(null);

  const { absolute_assets, option_type_values, background, in_flow_assets } =
    wedding?.public_theme_v2?.components?.CONTENT_CONTAINER_HOME || {};

  const textAlignment = option_type_values?.TEXT_ALIGNMENT?.value || 'CENTER';

  const { containerRef, elementsRef, croppedDimensions } = useCroppedBottomAbsoluteAssets(
    absolute_assets,
    Boolean(inPreview)
  );
  const scrollDownToBodyContent = () => {
    bodyContentRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      <MultiPageHeroContainer
        pageData={pageData}
        isSamplePage={isSamplePage}
        onDownCaretClick={scrollDownToBodyContent}
      />
      <HomeContentContainer
        containerWidth={containerRef?.current?.offsetWidth}
        device={pickDeviceProperties(device)}
        inFlowAssets={in_flow_assets}
        ref={containerRef}
        style={mapBackgroundToInlineStyle(background)}
      >
        {renderAbsoluteAssets({
          a: absolute_assets,
          elementsRef,
          croppedDimensions,
          containerWidth: containerRef?.current?.offsetWidth,
          device,
        })}
        <InnerContainer>
          <Home pageData={pageData} ref={bodyContentRef} textAlignment={textAlignment} />
          <Footer showDarkMode={isDarkColor(backgroundColors.homepageContent)} pageType="home" />
        </InnerContainer>
      </HomeContentContainer>
    </>
  );
};

export default HomeContainer;
