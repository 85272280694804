import React, { useContext, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from '@emotion/styled';
import type {
  WCmsHomepageView,
  WPublicNavItemView,
  WCmsVideoView,
} from '@zola/svc-web-api-ts-client';
import VideoSnippet from '~/components/util/VideoSnippet';
import { DeviceContext } from '~/contexts/DeviceContext';
import { renderInFlowAsset } from '~/components/publicWebsiteV2/util/renderHelpers';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from '~/components/publicWebsiteV2/context';
import { pickDeviceProperties } from '~/components/publicWebsiteV2/util/getRelativeImgSize';
import { PageAnimationWrapper } from '~/components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import NamesModule from '../../common/NamesModule';
import StorySection from './StorySection';
import {
  HomepageDividerFlourish,
  PaddedContentContainer,
  Hashtag,
  StyledLocationDateModule,
  StyledDateCountdown,
  RSVPButtonContainer,
  StyledFooterImage,
} from './Home.styles';
import getPublicWebsiteHref from '../../util/getPublicWebsiteHref';
import { stripTimeStamp } from './DateCountdown/helpers';

type HomePageType = WCmsHomepageView & {
  homepage_video?: WCmsVideoView[];
};

type HomeProps = {
  pageData?: HomePageType;
  textAlignment?: string;
  /** Used on single page; doesn't render names and adds top padding */
  hideNames?: boolean;
  disableAssets?: boolean;
  handleExplicitNavClick?: (s: string) => void;
};

const Home = React.forwardRef(
  (
    {
      pageData,
      textAlignment = 'CENTER',
      hideNames,
      disableAssets,
      handleExplicitNavClick,
    }: HomeProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { footer_image_url, homepage_sections: entities, video } = pageData || {};
    const {
      state: {
        components: { ThemedButton, styleGlobalBodyFont },
        wedding,
      },
    } = useWebsiteThemeContext();
    const { device } = useContext(DeviceContext);
    const { nav_items = [], slug, public_theme_v2 } = wedding || {};
    const showRSVP =
      nav_items.filter((n) => n.type === (('RSVP' as unknown) as WPublicNavItemView.TypeEnum))
        .length > 0;
    const homepageDividerComponent = public_theme_v2?.components?.HOMEPAGE_DIVIDER;

    const router = useRouter();

    const dividerRef = useRef(null);
    const { width: containerWidth } = useContainerDimensions(dividerRef);

    const { in_flow_assets_by_layout: inFlowAssetsByLayout } =
      wedding?.public_theme_v2?.components?.FIXED_WIDTH_CONTAINER || {};

    const {
      owner_first_name,
      owner_last_name,
      partner_first_name,
      partner_last_name,
      hashtag,
      wedding_date_range_start,
      wedding_date,
    } = wedding || {};

    const showCustomDateMessage = Boolean(wedding?.custom_date_message);
    const weddingCountdownHidden = Boolean(wedding?.wedding_countdown_hidden);
    const StyledHashtag = styleGlobalBodyFont(Hashtag);
    const StyledButton = styled(ThemedButton)`
      width: 300px;
      ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
        width: 200px;
      }

      ${({ theme }) => theme.MEDIA_QUERY.MOBILE_XS} {
        width: 100%;
      }
    `;

    return (
      <>
        {renderInFlowAsset(inFlowAssetsByLayout, 'before')}
        <PaddedContentContainer ref={ref} textAlignment={textAlignment} addTopPadding={hideNames}>
          <NamesModule
            ownerFirstName={owner_first_name}
            ownerLastName={owner_last_name}
            partnerFirstName={partner_first_name}
            partnerLastName={partner_last_name}
            textAlignment={textAlignment}
            hideNames={hideNames}
          />

          {hashtag && (
            <PageAnimationWrapper>
              <StyledHashtag>#{hashtag}</StyledHashtag>
            </PageAnimationWrapper>
          )}

          <PageAnimationWrapper
            animationProps={{ direction: { panDirection: 'right', wipeDirection: 'right' } }}
          >
            <StyledLocationDateModule textAlignment={textAlignment} />
            {wedding_date && !showCustomDateMessage && !weddingCountdownHidden && (
              <div data-testid="WeddingCountdown">
                <StyledDateCountdown
                  startDate={stripTimeStamp(wedding_date_range_start || wedding_date)}
                />
              </div>
            )}
          </PageAnimationWrapper>

          {showRSVP && (
            <PageAnimationWrapper>
              <RSVPButtonContainer>
                {handleExplicitNavClick ? (
                  <StyledButton onClick={() => handleExplicitNavClick('rsvp')}>RSVP</StyledButton>
                ) : (
                  <Link href={getPublicWebsiteHref(slug, 'rsvp', router?.query)}>
                    <StyledButton>RSVP</StyledButton>
                  </Link>
                )}
              </RSVPButtonContainer>
            </PageAnimationWrapper>
          )}

          {showRSVP && (entities || []).length > 0 && !disableAssets && (
            <PageAnimationWrapper>
              <HomepageDividerFlourish
                containerWidth={containerWidth}
                device={pickDeviceProperties(device)}
                inFlowAsset={homepageDividerComponent?.in_flow_assets}
                ref={dividerRef}
              />
            </PageAnimationWrapper>
          )}
          {video && (
            <PageAnimationWrapper
              animationProps={{ direction: { wipeDirection: 'left', panDirection: 'left' } }}
            >
              <VideoSnippet
                url={video.url}
                caption={video.caption}
                id={video.id}
                key={video.id}
                additionalPaddingBottom={!entities?.length}
                textAlignment={textAlignment}
              />
            </PageAnimationWrapper>
          )}
          <StorySection
            homepageSections={entities}
            video={video}
            textAlignment={textAlignment}
            disableAssets={disableAssets}
          />
        </PaddedContentContainer>
        {renderInFlowAsset(inFlowAssetsByLayout, 'after')}
        <PageAnimationWrapper>
          <StyledFooterImage imageUrl={footer_image_url} />
        </PageAnimationWrapper>
      </>
    );
  }
);

export default Home;
